<script lang="ts">
	import { writable } from 'svelte/store'
	import { page } from '$app/stores'
	import Menu from 'lucide-svelte/icons/menu'
	import X from 'lucide-svelte/icons/x'
	import Twitter from '~icons/simple-icons/x'
	import Discord from '~icons/simple-icons/discord'
	import * as m from '$lib/paraglide/messages'
	import { phg } from '$lib/utils/posthog'
	import Button from '$lib/shadcn/components/ui/button/button.svelte'
	import Separator from '$lib/shadcn/components/ui/separator/separator.svelte'
	import MobileMenu from './mobile-menu.svelte'

	const mobileMenuOpen = writable(false)
</script>

<header
	class="relative top-0 z-4 h-[72px] w-full border-b border-border/50 backdrop-blur-sm"
	class:bg-background={$mobileMenuOpen}
>
	<nav
		class="flex h-full items-center justify-between px-3 py-2 xl:container"
		aria-label={m.nav_menu_name()}
	>
		<a href="/" class="link-branding-gradient w-fit rounded-xs text-xl font-bold">
			{m.name()}
		</a>

		<div class="hidden h-full items-center gap-5 md:flex">
			<ul class="flex items-center gap-4">
				<li>
					<a
						href="https://twitter.com/chainspect_app"
						target="_blank"
						rel="noopener"
						class="flex items-center justify-center rounded-xs text-muted-foreground transition-colors hover:text-foreground"
						aria-label={m.socials_twitter()}
						on:click={() => {
							phg?.capture('twitter_link_click', {
								place: 'header_icon',
							})
						}}
					>
						<Twitter width="18" height="18" />
					</a>
				</li>

				<li>
					<a
						href="https://discord.gg/nnmJf56XYU"
						target="_blank"
						rel="noopener"
						class="flex items-center justify-center rounded-xs text-muted-foreground transition-colors hover:text-foreground"
						aria-label={m.socials_discord()}
						on:click={() => {
							phg?.capture('discord_link_click', {
								place: 'header_icon',
							})
						}}
					>
						<Discord width="23" height="23" />
					</a>
				</li>
			</ul>

			<Separator orientation="vertical" class="h-6" />

			<ul class="hidden gap-6 md:flex">
				<li>
					<a
						href="/dashboard"
						class="rounded-xs bg-transparent text-center text-sm hover:text-foreground {$page.url
							.pathname === '/dashboard'
							? 'text-foreground'
							: 'text-muted-foreground'}"
						on:click={() => {
							if ($page.url.pathname === '/') {
								phg?.capture('open_dashboard', {
									place: 'header_link',
								})
							}
						}}
					>
						{m.link_dashboard()}
					</a>
				</li>

				<li>
					<a
						href="/compare"
						class="rounded-xs bg-transparent text-center text-sm hover:text-foreground {$page.url
							.pathname === '/compare'
							? 'text-foreground'
							: 'text-muted-foreground'}"
					>
						{m.link_compare()}
					</a>
				</li>

				<li>
					<a
						href="/blog"
						class="rounded-xs bg-transparent text-center text-sm hover:text-foreground {$page.url
							.pathname === '/blog'
							? 'text-foreground'
							: 'text-muted-foreground'}"
					>
						{m.link_blog()}
					</a>
				</li>

				<li>
					<a
						href="/about"
						class="rounded-xs bg-transparent text-center text-sm hover:text-foreground {$page.url
							.pathname === '/about'
							? 'text-foreground'
							: 'text-muted-foreground'}"
					>
						{m.link_about()}
					</a>
				</li>
			</ul>
		</div>

		{#if $page.url.pathname === '/'}
			<div class="hidden md:block">
				<Button
					href="/dashboard"
					variant="secondary"
					on:click={() => {
						phg?.capture('open_dashboard', {
							place: 'header_button',
						})
					}}
				>
					{m.dashboard_cta()}
				</Button>
			</div>
		{/if}

		<Button
			type="button"
			variant="outline"
			size="icon"
			class="flex md:hidden"
			on:click={() => {
				mobileMenuOpen.update(prevOpen => !prevOpen)
			}}
			aria-label={$mobileMenuOpen ? m.mobile_menu_close() : m.mobile_menu_open()}
		>
			{#if $mobileMenuOpen}
				<X size="24" />
			{:else}
				<Menu size="24" />
			{/if}
		</Button>
	</nav>
</header>

<MobileMenu open={mobileMenuOpen} />
