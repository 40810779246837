<script lang="ts">
	import { phg } from '$lib/utils/posthog'
	import * as m from '$lib/paraglide/messages'
	import Button from '$lib/shadcn/components/ui/button/button.svelte'
	import * as Dialog from '$lib/shadcn/components/ui/dialog'

	let getListedDialogOpen = false
	let getListedDialogState: 'question' | 'community-member-instructions' = 'question'
</script>

<Dialog.Root bind:open={getListedDialogOpen} preventScroll>
	<footer
		class="mt-auto flex items-center justify-between gap-3 border-t border-border/50 bg-background py-6 sm:border-t-0 sm:py-10"
	>
		<nav class="flex w-full flex-col gap-10 px-3 xl:container">
			<div class="flex flex-col items-start justify-between gap-4 xs:flex-row xs:items-center">
				<a href="/" class="link-branding-gradient w-fit rounded-xs text-xl font-bold xs:text-2xl">
					{m.name()}
				</a>

				<Button
					variant="outline"
					size="sm"
					on:click={() => {
						getListedDialogOpen = true

						phg?.capture('get_listed_dialog_open', {
							place: 'footer',
						})
					}}
				>
					{m.get_listed_cta()}
				</Button>
			</div>

			<ul
				class="grid grid-cols-2 gap-x-1 gap-y-10 px-1 text-sm xs:text-base md:grid-cols-3 md:gap-10"
			>
				<li>
					<p class="font-semibold">
						{m.footer_resources()}
					</p>

					<ul class="mt-3 flex flex-col gap-3 xs:mt-4 xs:gap-4">
						<li>
							<a
								href="/dashboard"
								class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							>
								{m.link_dashboard()}
							</a>
						</li>

						<li>
							<a
								href="/compare"
								class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							>
								{m.link_compare()}
							</a>
						</li>

						<li>
							<a
								href="/blog"
								class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							>
								{m.link_blog()}
							</a>
						</li>

						<li>
							<a
								href="/about"
								class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							>
								{m.link_about()}
							</a>
						</li>
					</ul>
				</li>

				<li>
					<p class="font-semibold">
						{m.footer_community()}
					</p>

					<ul class="mt-3 flex flex-col gap-3 xs:mt-4 xs:gap-4">
						<li>
							<a
								href="https://twitter.com/chainspect_app"
								target="_blank"
								rel="noopener"
								class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
								on:click={() => {
									phg?.capture('twitter_link_click', {
										place: 'footer_text',
									})
								}}
							>
								{m.socials_twitter()}
							</a>
						</li>

						<li>
							<a
								href="https://discord.gg/nnmJf56XYU"
								target="_blank"
								rel="noopener"
								class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
								on:click={() => {
									phg?.capture('discord_link_click', {
										place: 'footer_text',
									})
								}}
							>
								{m.socials_discord()}
							</a>
						</li>
					</ul>
				</li>

				<li>
					<p class="font-semibold">
						{m.footer_contacts()}
					</p>

					<ul class="mt-3 flex flex-col gap-3 xs:mt-4 xs:gap-4">
						<li>
							<a
								href="https://t.me/chainspect_app"
								target="_blank"
								rel="noopener"
								class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							>
								{m.socials_telegram()}
							</a>
						</li>

						<li>
							<a
								href="mailto:contact@chainspect.app"
								target="_blank"
								rel="noopener"
								class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							>
								{m.social_email()}
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</nav>
	</footer>

	<Dialog.Content>
		<Dialog.Header>
			<Dialog.Title>{m.get_listed_cta()}</Dialog.Title>
		</Dialog.Header>

		{#if getListedDialogState === 'question'}
			<div class="prose text-center sm:text-left">
				<p>{m.chain_page_get_listed_dialog_question()}</p>
			</div>

			<div class="mt-1 flex items-center justify-center gap-4">
				<Button
					variant="outline"
					size="sm"
					on:click={() => {
						getListedDialogState = 'community-member-instructions'

						phg?.capture('get_listed_dialog_community_member_instructions')
					}}
				>
					{m.chain_page_get_listed_dialog_community_member()}
				</Button>

				<Button
					variant="outline"
					size="sm"
					href="https://docs.google.com/forms/d/e/1FAIpQLSfRL5XJ-DpK08adpggqjZb71ru0Og1bnnUM-34yTe8dWTNyPw/viewform?usp=sf_link"
					target="_blank"
					on:click={() => {
						phg?.capture('get_listed_dialog_blockchain_team_form')
					}}
				>
					{m.chain_page_get_listed_dialog_team_member()}
				</Button>
			</div>
		{:else if getListedDialogState === 'community-member-instructions'}
			<div class="prose">
				{m.chain_page_get_listed_dialog_general_community_memeber_instructions()}
			</div>

			<div class="mt-1 flex items-center justify-stretch gap-6">
				<Button
					variant="outline"
					size="sm"
					class="!w-full"
					on:click={() => {
						getListedDialogState = 'question'
					}}
				>
					{m.chain_page_get_listed_dialog_back()}
				</Button>
			</div>
		{/if}
	</Dialog.Content>
</Dialog.Root>
