<script lang="ts">
	import { fly } from 'svelte/transition'
	import { cubicInOut } from 'svelte/easing'
	import type { Writable } from 'svelte/store'
	import { createDialog, melt } from '@melt-ui/svelte'
	import { page } from '$app/stores'
	import { beforeNavigate } from '$app/navigation'
	import ArrowRight from 'lucide-svelte/icons/arrow-right'
	import Twitter from '~icons/simple-icons/x'
	import Discord from '~icons/simple-icons/discord'
	import * as m from '$lib/paraglide/messages'
	import { phg } from '$lib/utils/posthog'

	export let open: Writable<boolean>

	const {
		elements: { portalled, content },
		states: { open: isOpen },
	} = createDialog({
		open,
		closeOnOutsideClick: false,
		forceVisible: true,
	})

	beforeNavigate(() => {
		open.set(false)
	})
</script>

<div use:melt={$portalled}>
	{#if $isOpen}
		<div
			use:melt={$content}
			class="fixed bottom-0 left-0 right-0 top-[72px] z-3 h-[calc(100dvh-72px)] bg-background md:hidden"
			transition:fly={{ duration: 250, x: 350, easing: cubicInOut }}
		>
			<nav class="flex h-full flex-col gap-4 px-1 py-2" aria-label={m.mobile_menu_name()}>
				<ul class="flex flex-col gap-3">
					<li>
						<a
							href="/dashboard"
							class="hover:text-semibold flex w-full items-center justify-between gap-1 px-2 py-3 font-semibold {$page
								.url.pathname === '/dashboard'
								? 'text-foreground'
								: 'text-muted-foreground'}"
							on:click={() => {
								if ($page.url.pathname === '/') {
									phg?.capture('open_dashboard', {
										place: 'mobile_menu_link',
									})
								}
							}}
						>
							{m.link_dashboard()}

							<ArrowRight size="22" />
						</a>
					</li>

					<li>
						<a
							href="/compare"
							class="hover:text-semibold flex w-full items-center justify-between gap-1 px-2 py-3 font-semibold {$page
								.url.pathname === '/compare'
								? 'text-foreground'
								: 'text-muted-foreground'}"
						>
							{m.link_compare()}

							<ArrowRight size="22" />
						</a>
					</li>

					<li>
						<a
							href="/blog"
							class="hover:text-semibold flex w-full items-center justify-between gap-1 px-2 py-3 font-semibold {$page
								.url.pathname === '/blog'
								? 'text-foreground'
								: 'text-muted-foreground'}"
						>
							{m.link_blog()}

							<ArrowRight size="22" />
						</a>
					</li>

					<li>
						<a
							href="/about"
							class="hover:text-semibold flex w-full items-center justify-between gap-1 px-2 py-3 font-semibold {$page
								.url.pathname === '/about'
								? 'text-foreground'
								: 'text-muted-foreground'}"
						>
							{m.link_about()}

							<ArrowRight size="22" />
						</a>
					</li>
				</ul>

				<ul class="mb-4 mt-auto flex items-center justify-center gap-4">
					<li>
						<a
							href="https://twitter.com/chainspect_app"
							target="_blank"
							rel="noopener"
							class="flex items-center justify-center rounded-xs text-muted-foreground transition-colors hover:text-foreground"
							aria-label={m.socials_twitter()}
							on:click={() => {
								phg?.capture('twitter_link_click', {
									place: 'mobile_menu_icon',
								})
							}}
						>
							<Twitter width="22" height="22" />
						</a>
					</li>

					<li>
						<a
							href="https://discord.gg/nnmJf56XYU"
							target="_blank"
							rel="noopener"
							class="flex items-center justify-center rounded-xs text-muted-foreground transition-colors hover:text-foreground"
							aria-label={m.socials_discord()}
							on:click={() => {
								phg?.capture('discord_link_click', {
									place: 'mobile_menu_icon',
								})
							}}
						>
							<Discord width="26" height="26" />
						</a>
					</li>
				</ul>
			</nav>
		</div>
	{/if}
</div>
